import { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'

import { useAuth0 } from '@auth0/auth0-react'

import usePPA from '../../../../hooks/use-ppa'

import { parseSnippetAddress } from '../../../../utils'

import { UserAuth0 } from '../../../../types/auth0'

import { isFeatureEnabled } from '../../../../utils/features'

import Button from '../../../../components/atoms/button'
import Input from '../../../../components/atoms/input'
import TextArea from '../../../../components/atoms/text-area'
import DefList from '../../../../components/atoms/def-list'
import { feedbackMessage } from '../../../../components/atoms/feedback'
import SelectComponent from '../../../../components/atoms/select'
import InputFile from '../../../../components/atoms/input-file'

import Stepper from '../../../../components/atoms/stepper'

import InputGroup from '../../../../components/molecules/input-group'

import { transition } from '../../../../styles'

import { ReactComponent as OfficeSVG } from '../assets/office.svg'

import CreateCompanyAndMemberForm, { FormValues } from './form-add-company'

/**
 * @FIXME
import { StorageObject, SupplierProfile } from '../../../../types'
 */

type Profile = {
  logoFile?: File
  description?: string | null
  totalCapacity?: number
  backing?: string[]
  lenders?: string[]
  other?: string | null
}

type FormData = {
  profile: Profile
}

const formDefaultValues: FormData = {
  profile: {
    logoFile: undefined,
    description: '',
    totalCapacity: 0,
    backing: [],
    lenders: [],
    other: '',
  },
}

const SupplierOnboarding: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.onboarding',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
    control,
  } = useForm<FormData>({
    defaultValues: formDefaultValues,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [supplierProfile, setSupplierProfile] = useState<FormData | undefined>()
  const [companyAndMember, setCompanyAndMember] = useState<
    FormValues | undefined
  >()

  const [currentStep, setCurrentStep] = useState(0)

  const handleSubmitForm = (formData: FormData) => {
    const {
      profile: { description, totalCapacity, backing, lenders, other },
    } = formData

    const payload: any = {
      profile: {
        description: description || null,
        totalCapacity: totalCapacity || 0,
        backing: (backing || []).map((item: any) => {
          return item.value || null
        }),
        lenders: (lenders || []).map((item: any) => {
          return item.value || null
        }),
        other: other || null,
      },
    }

    setSupplierProfile(payload)
    setCurrentStep(2)
  }

  const handleCreateCompanyAndMemberFormSubmit = (formData: FormValues) => {
    setCompanyAndMember(formData)
    setCurrentStep(1)
  }

  const handleSubmitCompanyAndProfile = async () => {
    if (isLoading || process.env.REACT_APP_COMPANY !== 'PPAYA') return
    setIsLoading(true)

    if (companyAndMember === undefined || supplierProfile === undefined) {
      setCurrentStep(0)
      return
    }

    const supplierWithCompanyProfileAndBrokerContext = {
      ...supplierProfile,
      ...companyAndMember,
      brokerContextName: process.env.REACT_APP_COMPANY,
    }

    try {
      const { error } = await fetchData({
        method: 'POST',
        url: '/core/onboarding/supplier',

        body: supplierWithCompanyProfileAndBrokerContext,
      })

      /**
       * @TODO Check  way of using an env variable with the environment so we can apply the prefix or not based on the environment that you are using the app
       */

      if (error) throw error

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t(
            'setup.handleCompleteSupplierProfile.success.description',
          ),
        },
        'success',
      )

      setCurrentStep(3)
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'onboardingSupplier',
      })
      setCurrentStep(0)
    } finally {
      setIsLoading(false)
    }
  }

  /**
     * @WARNING
     * @FIXME
     * The create Logo
     *  const createLogo = async (logo: File) => {
    try {
      const {
        data: {
          supplierCreateLogoStorageObject: { userErrors, ...storageObject },
        },
        errors,
      } = await mutateSupplierCreateLogo({
        variables: {
          id: userCompanyData?.id,
          contentType: logo.type,
        },
      })

      if (errors && errors?.length > 0) throw errors[0]
      if (userErrors && userErrors?.length > 0) throw userErrors[0]

      const formData = new FormData()
      Object.entries(JSON.parse(storageObject?.uploadFormData)).forEach(
        ([formKey, value]) => {
          formData.append(formKey, value as string | Blob)
        },
      )
      formData.append('file', logo)

      await axios.post(storageObject?.uploadUrl, formData)

      return storageObject.url
    } catch (error) {
      console.error(error)
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t('setup.handleEditSupplierLogo.error.description'),
        },
        'error',
      )
    }
    return undefined
  }
     */

  const { user } = useAuth0<UserAuth0>()

  useEffect(() => {
    if (!user?.app_metadata?.status || currentStep === 3) return
    if (user?.app_metadata?.status === 'REVIEW') {
      setCurrentStep(3)
    }
  }, [user])

  useEffect(() => {
    if (process.env.REACT_APP_COMPANY !== 'PPAYA') {
      window.location.reload()
    }
  }, [])

  return (
    <div className="flex flex-col items-center">
      <div className={classNames('mb-10 fade-in')}>
        <Stepper
          steps={[
            t('steps.companySetup'),
            t('steps.profileSetup'),
            t('steps.profileReview'),
            t('steps.validation'),
          ]}
          activeStep={currentStep}
          setActiveStep={setCurrentStep}
          disabledStep={currentStep === 3}
        />
      </div>
      {currentStep === 0 && (
        <CreateCompanyAndMemberForm
          onSubmit={handleCreateCompanyAndMemberFormSubmit}
          defaultValues={companyAndMember as any}
        />
      )}
      {currentStep === 1 && (
        <form
          className={classNames(
            transition,
            'fade-in',
            'flex flex-col gap-y-3 w-full max-w-[500px]',
          )}
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <section className="flex flex-col text-ppa/title gap-y-5 mx-6">
            <div className="flex flex-col gap-y-5">
              <h1 className="text-2xl text-ppa/title font-medium">
                {t('setup.profile.title')}
              </h1>
              <span className="text-base font-light">
                {t('setup.profile.info')}
              </span>
              {isFeatureEnabled('supplierLogoFile') && (
                <Controller
                  name="profile.logoFile"
                  control={control}
                  render={({ field: { onChange: _, ref: __, ...props } }) => (
                    <InputGroup
                      label={t('setup.fields.logo.label')}
                      infoLabel={t('setup.fields.logo.infoLabel')}
                    >
                      <InputFile
                        {...props}
                        name="logoFile"
                        setValue={() => console.log('this is logo file')}
                        aria-label="logoFile"
                        clickToRemove
                        error={formErrors?.profile?.logoFile?.message}
                      />
                    </InputGroup>
                  )}
                />
              )}
              <Controller
                name="profile.description"
                control={control}
                render={({ field: { ...props } }) => (
                  <InputGroup label={t('setup.fields.servicesText.label')}>
                    <TextArea
                      className="w-full h-32 rounded-md border border-ppa/placeholder pt-1 pl-2.5 text-ppa/title"
                      placeholder={t('setup.fields.servicesText.placeholder')}
                      aria-label="name"
                      error={formErrors?.profile?.description?.message}
                      {...props}
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="profile.totalCapacity"
                control={control}
                render={({ field: { ...props } }) => (
                  <InputGroup
                    label={t(
                      'setup.fields.renewableEnergyUnderOperation.label',
                    )}
                  >
                    <Input
                      type="text"
                      placeholder="0.0"
                      suffix="MW"
                      aria-label="profile.totalCapacity"
                      error={formErrors?.profile?.totalCapacity?.message}
                      {...props}
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="profile.backing"
                control={control}
                render={({ field: { ref: _, ...props } }) => (
                  <InputGroup label={t('setup.fields.financialBacking.label')}>
                    <SelectComponent
                      type="multi"
                      options={[
                        {
                          value: 'bankBond',
                          label: t(
                            'setup.fields.financialBacking.options.option1.label',
                          ),
                        },
                        {
                          value: 'pcg',
                          label: t(
                            'setup.fields.financialBacking.options.option2.label',
                          ),
                        },
                        {
                          value: 'strongCreditRating',
                          label: t(
                            'setup.fields.financialBacking.options.option3.label',
                          ),
                        },
                      ]}
                      placeholder={t(
                        'setup.fields.financialBacking.placeholder',
                      )}
                      aria-label="financialBacking"
                      testId="select-financial"
                      error={formErrors?.profile?.backing?.message}
                      {...props}
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="profile.lenders"
                control={control}
                render={({ field: { ref: _, onChange, value, ...props } }) => (
                  <InputGroup
                    label={t('setup.fields.directlyAuthorisedLenders.label')}
                  >
                    <SelectComponent
                      type="multi"
                      options={[]}
                      onCreateOption={(label) => {
                        return {
                          label,
                          value: label,
                        }
                      }}
                      value={value}
                      onChange={onChange}
                      placeholder={t(
                        'setup.fields.directlyAuthorisedLenders.placeholder',
                      )}
                      aria-label="directlyAuthorisedLenders"
                      testId="select-lenders"
                      error={formErrors?.profile?.lenders?.message}
                      {...props}
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="profile.other"
                control={control}
                render={({ field: { ...props } }) => (
                  <InputGroup label={t('setup.fields.marketingText.label')}>
                    <TextArea
                      className="w-full h-32 rounded-md border border-ppa/placeholder pt-1 pl-2.5 text-ppa/title"
                      placeholder="Example: this and that, none, etc"
                      aria-label="marketingText"
                      error={formErrors?.profile?.other?.message}
                      {...props}
                    />
                  </InputGroup>
                )}
              />
            </div>
            <div className="flex justify-end mt-5 ml-5 gap-x-5">
              <div className="w-full max-w-[100px]">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    setCurrentStep(0)
                  }}
                  fullWidth
                >
                  {t('review.goBack')}
                </Button>
              </div>
              <div className="w-40">
                <Button
                  variant="primary"
                  type="submit"
                  fullWidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {t('setup.nextButton')}
                </Button>
              </div>
            </div>
          </section>
        </form>
      )}
      {currentStep === 2 && (
        <>
          <div
            className={classNames(
              transition,
              'fade-in',
              'flex flex-col gap-y-5',
              'max-w-[600px] w-full',
            )}
          >
            <div className="flex flex-col items-start justify-start text-ppa/title">
              <h2 className="text-2xl font-medium mb-3">{t('review.title')}</h2>

              <span className="text-base font-light">
                <Trans
                  ns="private/index"
                  i18nKey="generators.underReview.review.info"
                  defaults="Please review the information you provided. When you're ready to validate your company details, click <span>Submit</span>."
                  components={{
                    span: <span className="font-medium" />,
                  }}
                />
              </span>
            </div>

            <DefList
              rowKeys={[
                {
                  keyName: 'name',
                  title: t('review.defList.name'),
                  renderCustomEl: (item: any) => {
                    return item?.company?.name || ''
                  },
                },
                {
                  keyName: 'number',
                  title: t('review.defList.number'),
                  renderCustomEl: (item: any) => {
                    return item?.company?.number || ''
                  },
                },
                {
                  keyName: 'address',
                  title: t('review.defList.address'),
                  renderCustomEl: (item: any) => {
                    return item?.company?.address
                      ? parseSnippetAddress(item.company.address)
                      : ''
                  },
                },
                ...(companyAndMember?.company?.billingAddress
                  ? [
                      {
                        keyName: 'billingAddress',
                        title: t('review.defList.billingAddress'),
                        renderCustomEl: (item: any) => {
                          return item?.company?.billingAddress
                            ? parseSnippetAddress(item?.company.billingAddress)
                            : ''
                        },
                      },
                    ]
                  : []),
                {
                  keyName: 'MemberName',
                  title: 'Name',
                  renderCustomEl: (item: any) => {
                    return item?.member ? (
                      <span>
                        {item.member?.firstName} {item.member?.lastName}
                      </span>
                    ) : (
                      ''
                    )
                  },
                },
                {
                  keyName: 'phoneNumber',
                  title: 'Phone number',
                  renderCustomEl: (item: any) => {
                    return item?.member?.phoneNumber || ''
                  },
                },
              ]}
              data={companyAndMember}
            />
          </div>

          <div
            className={classNames(
              transition,
              'fade-in',
              'flex flex-col gap-x-5',
              'max-w-[600px] w-full mt-11',
            )}
          >
            <DefList
              rowKeys={[
                {
                  keyName: 'description',
                  title: t(
                    'setup.supplierProfileDefList.titles.servicesText.title',
                  ),
                  smallTitle: t(
                    'setup.supplierProfileDefList.titles.servicesText.smallTitle',
                  ),
                },
                {
                  keyName: 'totalCapacity',
                  title: t(
                    'setup.supplierProfileDefList.titles.renewableEnergyUnderOperation',
                  ),
                  suffix: 'MW',
                },
                {
                  keyName: 'backing',
                  title: t(
                    'setup.supplierProfileDefList.titles.financialBacking',
                  ),
                  renderCustomEl: (supplier: any) => {
                    return (supplier?.backing || []).join(', ')
                  },
                },
                {
                  keyName: 'lenders',
                  title: t(
                    'setup.supplierProfileDefList.titles.directlyAuthorisedLenders',
                  ),
                  renderCustomEl: (supplier: any) => {
                    return (supplier?.lenders || []).join(', ')
                  },
                },
                {
                  keyName: 'other',
                  title: t('setup.supplierProfileDefList.titles.marketingText'),
                },
              ]}
              data={supplierProfile?.profile}
            />

            <div className="flex items-center justify-end gap-x-3 mt-8">
              <div className="w-full max-w-[100px]">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    setCurrentStep(1)
                  }}
                  fullWidth
                >
                  {t('review.goBack')}
                </Button>
              </div>
              <div className="w-full max-w-[150px]">
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    handleSubmitCompanyAndProfile()
                  }}
                  fullWidth
                  loading={isLoading}
                >
                  {t('review.submit')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      {currentStep === 3 && (
        <section className="flex flex-col text-ppa/title gap-y-5">
          <h1 className="text-3xl font-semibold">{t('validation.title')}</h1>
          <span className="text-xl font-normal">{t('validation.info.0')}</span>
          <span className="text-xl font-normal">{t('validation.info.1')}</span>
          <div className="w-full max-w-[650px]">
            <OfficeSVG />
          </div>
        </section>
      )}
    </div>
  )
}

export default SupplierOnboarding
