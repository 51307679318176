/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { dateFormats } from '../../../../../../utils/data'

import { parseContractDuration } from '../../../../../../utils'

import DefList from '../../../../../../components/atoms/def-list'
import SupplierDefListModal from '../../../../../../components/organisms/supplier-details-modal'

import {
  SupplierTender,
  Supplier,
  SupplierProfile,
} from '../../../../../../types'

type ParticipatingSupplier = Supplier & {
  profile: SupplierProfile
}

interface TenderDetailsListSleevedProps {
  data: SupplierTender
}

const SleevedConsumerTenderDetailsDefList: React.FC<
  TenderDetailsListSleevedProps
> = ({ data }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sleevedTenders.details.defList',
  })

  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false)
  const [selectedSupplier, setSelectedSupplier] =
    useState<ParticipatingSupplier | null>(null)

  const rowKeys = useMemo(() => {
    return [
      {
        keyName: 'contractDuration',
        title: t('titles.contractDuration'),
        renderCustomEl: (item: SupplierTender['consumerTender']) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      {
        keyName: 'deadline',
        title: t('titles.deadline'),
        renderCustomEl: (item: SupplierTender) => {
          if (!item.deadline) return undefined
          return (
            <div className="flex text-center">
              {dayjs
                .utc(item.deadline)
                .tz('Europe/London')
                .format(dateFormats.userDateTime)}
            </div>
          )
        },
      },
      {
        keyName: 'targetMatch',
        title: t('titles.targetMatch'),
        suffix: '%',
        renderCustomEl: (item: SupplierTender['consumerTender']) => {
          if (!item.targetMatch) return undefined
          return item.targetMatch * 100
        },
      },
      {
        keyName: 'greenTariff',
        title: t('titles.greenTariff'),
        renderCustomEl: (item: SupplierTender['consumerTender']) => {
          if (!item?.greenTariff) return undefined
          return item.greenTariff ? 'Yes' : 'No'
        },
      },
      {
        keyName: 'participatingSuppliers',
        title: t('titles.participatingSuppliers'),
        renderCustomEl: (item: SupplierTender['consumerTender']) => {
          if (!item.consumerTenderInvitations) return undefined
          const participatingSuppliers = item.consumerTenderInvitations.map(
            (invitation) => (
              <span
                key={invitation.supplier.id}
                onClick={() => {
                  setSelectedSupplier(invitation.supplier as any)
                  setIsSupplierModalOpen(true)
                }}
                className="border-b border-ppa/grayText cursor-pointer"
              >
                {invitation.supplier.name},
              </span>
            ),
          )

          return (
            <div className="cursor-pointer flex flex-row space-x-2">
              {participatingSuppliers}
            </div>
          )
        },
      },
    ]
  }, [data])

  return (
    <div>
      <h2 className="font-semibold text-lg text-ppa/title mt-5 mb-3">
        {t('sleevedTenderTitle')}
      </h2>
      <DefList rowKeys={rowKeys} data={data} />
      {selectedSupplier && (
        <SupplierDefListModal
          isOpen={isSupplierModalOpen}
          onClose={() => setIsSupplierModalOpen(false)}
          title={selectedSupplier.name}
          supplierProfileData={selectedSupplier.profile as any}
        />
      )}
    </div>
  )
}

export default SleevedConsumerTenderDetailsDefList
